<template>
  <router-link
    :to="item.link"
    tag="div"
    class="nav-box-lists"
  >
    {{ item.name }}
  </router-link>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-box-lists {
  padding-left: 50px;
}
</style>
