<template>
  <div>
    <div
    @click="navLink(item.link)"
      class="nav-box"
    >
      {{ item.name }}
      <span @click.stop="onMenuItemClick">
        <i
          class="iconfont"
          :class="iconClass"
          v-if="fang"
        ></i>
      </span>

    </div>
    <div v-if="isOpen">
      <div
        class="nav-list-option"
        v-for="subItem in item.list"
        :key="subItem.name"
      >
        <router-link
          class="nav-list-option-list"
          tag="div"
          :to="subItem.link"
        >{{ subItem.name }}</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  created() {
    const { path } = this.$route;
    const childrenPath = this.item.list.map((child) => child.link);
    if (childrenPath.includes(path)) {
      this.isOpen = false;
    }
    if (childrenPath.length === 0) {
      this.fang = false;
    }
  },
  data() {
    return {
      isOpen: false,
      fang: true,
    };
  },
  computed: {
    iconClass() {
      if (this.isOpen) {
        return 'icon-jian';
      }
      return 'icon-jia';
    },
  },
  methods: {
    navLink(navLink) {
      console.log(this.$route.path);
      if (navLink !== '/about-qualifications') {
        this.$router.push({
          path: navLink,
        });
      }
    },
    onMenuItemClick() {
      this.isOpen = !this.isOpen;
    },
  },
};
</script>

<style lang='scss' scoped>
.nav-list-option {
  &-list {
    background: #eee;
    position: relative;
    top: 0;
    width: 520px;
    text-align: center;
    transition: all 3000ms;
  }
}
.iconfont {
  position: absolute;
  top: 0;
  right: 50px;
  font-size: 40px;
}

.nav-box {
  padding-left: 50px;
}
</style>
