<template>
  <div class="nav">
    <div class="head-nav">
      <div
        class="list"
        @click="onNavButtonClick"
      >
        <img
          src="@/assets/image/head-nav/1.png"
          alt="nav button"
        />
      </div>
      <div class="list">
        <span>{{ $t(title) }}</span>
      </div>
      <div class="list">
        <img
          @click="onI18nClick"
          :src="$t('headNav.img')"
          alt=""
        />
      </div>
    </div>

    <div
      class="navs-mask"
      :class="navsMask"
      @click="onHideClick"
    ></div>

    <div
      class="navs-boxs"
      :class="drawerBoxClass"
    >
      <div class="navs">
        <div class="navs-img">
          <img
            src="@/assets/image/head-nav/2.png"
            alt=""
          />
        </div>

        <nav class="nav-info">
          <ul class="nav-contetn">
            <li class="nav-list">
              <div>
                <router-link
                  tag="div"
                  to="/home"
                  class="nav-box"
                > {{$t('headNav.home')}} </router-link>
              </div>
            </li>
            <li
              class="nav-list"
              @click="navList()"
              v-for="(item, index) in nav"
              :key="index"
            >
              <MenuItem
                v-if="!item.list"
                :item="item"
              />
              <MenuItemGroup
                v-else
                :item="item"
              />
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import '@/assets/icon/iconfont.css';
import gql from 'graphql-tag';
import MenuItem from './componets/menu-item';
import MenuItemGroup from './componets/menu-item-group';

export default {
  apollo: {
    nav: {
      query() {
        if (this.$i18n.locale === 'cn') {
          return gql`
            query {
              nav: allPrimaryLabels {
                name
                link
                list: secondaryLabels {
                  name
                  link
                }
              }
            }
          `;
        }
        return gql`
          query {
            nav: allEnPrimaryLabels {
              name
              link
              list: secondaryLabels {
                name
                link
              }
            }
          }
        `;
      },
    },
  },
  components: {
    MenuItem,
    MenuItemGroup,
  },
  data() {
    return {
      nav: [],
      icon: true,
    };
  },
  computed: {
    isDrawerOpen() {
      return this.$store.state.app.isDrawerOpen;
    },
    title() {
      return this.$store.state.app.navTitle;
    },
    drawerBoxClass() {
      return {
        'navs-boxs-active': this.isDrawerOpen,
      };
    },
    navsMask() {
      return {
        'navs-mask-active': this.isDrawerOpen,
      };
    },
  },
  methods: {
    onI18nClick() {
      if (this.$i18n.locale === 'en') {
        this.$i18n.locale = 'cn';
      } else if (this.$i18n.locale === 'cn') {
        this.$i18n.locale = 'en';
      }
      this.$router.replace({
        query: {
          lang: this.$i18n.locale,
        },
      });
    },
    onNavButtonClick() {
      this.$store.commit('SET_DRAWER_STATE', true);
    },
    onHideClick() {
      this.$store.commit('SET_DRAWER_STATE', false);
    },

    navList() {
      this.icon = !this.icon;
    },
  },
};
</script>

<style lang='scss' scoped>
.nav {
  position: relative;

  .head-nav {
    display: flex;
    flex: 1;
    justify-self: start;
    width: 100%;

    .list {
      width: 160px;
      height: 110px;
      text-align: center;
      img {
        margin-top: 20px;
        width: 70px;
        height: 70px;
      }

      &:nth-child(2) {
        width: 430px;
        line-height: 110px;
        font-size: 36px;
      }
    }
  }

  .navs-mask {
    width: 100%;
    height: 100vh;
    background: #000;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -99;

    &-active {
      opacity: 0.8 !important;
      z-index: 2 !important;
    }
  }

  .navs-boxs {
    width: 520px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 999;
    transition: left 0.5s !important;

    .navs {
      width: 520px;
      height: 100vh;
      background: #fff;
      overflow: auto;

      &-img {
        width: 520px;
        height: 100px;
        line-height: 100px;
        img {
          width: 100%;
        }
      }

      .nav-info {
        width: 100%;

        .nav-contetn {
          width: 100%;
          position: relative;

          .nav-list {
            width: 100%;
            height: auto;
            line-height: 80px;
            font-size: 36px;
            background: #f8f8f8;
            border-bottom: 1px solid #eeeeee;
            position: relative;
          }
        }
      }

      .nav-btn {
        margin: 250px 0 100px 0;
        width: 520px;
        text-align: center;

        button {
          width: 400px;
          height: 70px;
          line-height: 70px;
          text-align: center;
          border-radius: 40px;
          border: none;
          outline: none;
          background: #ffbf00;
          font-weight: 600;
        }
      }
    }

    &-active {
      left: 0% !important;
    }
  }
}

@media screen and (max-width: 320px) {
  .nav .navs-boxs .navs .nav-btn {
    margin: 100px 0 60px 0;
  }
}
</style>

<style>
.router-link-exact-active {
  background: #ffbf00 !important;
  padding-left: -50px;
}

.nav-box {
  padding-left: 50px;
}
</style>
